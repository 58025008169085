import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { DEFAULT_AVATAR_DARK } from '../../constants/profile'
import { DesktopContactBar } from './ContactBar'
import { FC, useEffect } from 'react'
import { FLOWTAG_BANNER, FLOWTAG_SHOW_BANNER_PAGETYPES } from './GetFlowtag/GetFlowtag.constants'
import { FlowpageCaptionText } from '../util/FlowpageCaptionText'
import { FlowpageConsumerBanner } from './ConsumerBanner/ConsumerBanner'
import { FlowpageModals } from './FlowPageContent/modals'
import { FontFamilies } from '@dtx-company/true-common/src/constants/page'
import { LinkProvider } from '@dtx-company/inter-app/src/constants/linkTypes'
import { Links } from './FlowPageContent/subcomponents/Links'
import { MaskType, theme } from '@dtx-company/shared-components/src'
import { PROFILE_IMAGE_MASK_RADIUS } from '../../constants/customizeTheme'
import { PaddingProps } from 'styled-system'
import { PageType } from '@dtx-company/flow-codegen/src/page/generated.types'
import { PoweredByFooter } from './PoweredByFooter'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import {
  PageType as TypedPage,
  UnauthPage,
  UnauthPageQueryLink
} from '@dtx-company/flow-codegen/src/page/typeUtils'
import { Verified } from '../../components/Verified/Verified'
import { WIDGET_INFO } from '../widgets/manifest'
import { WidgetType } from '../widgets/types/widgetType'
import { checkRemoveBrandingEnabled } from '../RemoveBranding/utils'
import { getBgOpacity, getCustomThemeValues } from '../../utils/theme'
import { getGoogleFontStyleUrlFor } from '../profile/Customize/GoogleFonts/utils'
import { getWidgetComponent } from '../widgets/getWidgetComponent'
import { parseLinks, sortLinks } from '../../utils/main'
import { removeNulls } from '@dtx-company/true-common/src/utils/objects'
import { useFlowFeature } from '@dtx-company/inter-app/src/hooks/useFlowFeature/useFlowFeature'
import { usePageDisplayContext } from '../../context/page-display'
import { useScrollToWidget } from './FlowPageContent/hooks'
import Head from 'next/head'
import NextImage, { ImageProps } from 'next/image'
import PublishBanner from './PublishBanner'
import styled, { ThemeProvider, css } from 'styled-components'

export interface FlowPageProps {
  page: TypedPage | UnauthPage
  preview?: boolean
  isPresetFp?: boolean
  isOwnPage?: boolean
  hideFooterBanner?: boolean
  flowpageContentContainerStyleProps?: PaddingProps
  sentFromProfilePage?: boolean
  backgroundImageProps?: {
    opacity?: number
    position?: string
  } & Omit<ImageProps, 'src'>
}

export const FlowpageContainer = styled(Box)`
  position: relative;
  width: 100%;
  flex-direction: column;
  max-width: 100vw;
  justify-content: space-between;
`

interface FlowPageWrapperProps {
  preview: boolean
  showBanner: boolean
}
const FlowPageWrapper = styled.div<FlowPageWrapperProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  margin: 0 auto;
  padding-top: ${props => (props.showBanner ? '75px' : '0px')};
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  position: relative;
  ${(props): any | undefined => {
    if (props.preview) {
      return css`
        pointer-events: none;
      `
    }

    return
  }}
`

const NextProfileAvatar = styled(NextImage)<{ $borderRadius?: string }>`
  border-radius: ${props => props.$borderRadius};
  object-fit: contain;
`

const BackgroundImage = styled(NextImage)<
  {
    opacity: number
    position: string
  } & ImageProps
>`
  opacity: ${props => props.opacity};
  position: ${props => props.position};
  height: 100%;
  object-fit: cover;
`

const ProfileHeader: FC<{ page: TypedPage | UnauthPage }> = ({ page }) => (
  <Box mt="50px" mb="16px">
    <NextProfileAvatar
      width={140}
      height={140}
      priority
      $borderRadius={
        PROFILE_IMAGE_MASK_RADIUS[
          (page?.theme?.profileImageMaskType as MaskType) || MaskType.CIRCLE
        ]
      }
      src={page?.profileImage || DEFAULT_AVATAR_DARK}
      alt={`${page?.displayName}'s Avatar`}
    />
  </Box>
)

const FlowPageContent: FC<FlowPageProps> = ({
  page,
  preview = false,
  isOwnPage,
  isPresetFp = false,
  hideFooterBanner = false,
  flowpageContentContainerStyleProps,
  backgroundImageProps,
  sentFromProfilePage
}) => {
  const { setUnauthTheme } = usePageDisplayContext()
  const userLinks = removeNulls(page.links || [])
  const linkObj = parseLinks(userLinks)
  const { id } = page
  const { contact, links } = linkObj
  const [flowtagBannerEnabled] = useFlowFeature(FLOWTAG_BANNER)
  const { style, primaryColor, contrastText, baseColor, baseContrastText } = getCustomThemeValues(
    page?.theme
  )
  const backgroundImgUrl = page?.theme?.backgroundImgUrl
  const fontFamily = page?.theme?.fontFamily ?? FontFamilies.Inter
  const userTheme = {
    ...theme,
    fontFamily: `'${fontFamily}', ${theme.fontFamily}`,
    colors: {
      ...theme.colors,
      flowpage: { style, primaryColor, contrastText, backgroundImgUrl, baseColor, baseContrastText }
    }
  }
  const [fullPageLinks, cardLinks] = links.reduce(
    (acc: UnauthPageQueryLink[][], link) => {
      if (link.provider === LinkProvider.WIDGET) {
        const widgetType = link.type as WidgetType
        if (!(WIDGET_INFO[widgetType]?.disableFlowpagePreview && preview)) {
          if (WIDGET_INFO[widgetType]?.isFullPage) {
            acc[0].push(link)
          } else {
            acc[1].push(link)
          }
        }
      } else {
        acc[1].push(link)
      }
      return acc
    },
    [[], []]
  )
  const sortedCardLinks = sortLinks(cardLinks)

  const showBanner = isOwnPage || sentFromProfilePage ? !preview : false
  useScrollToWidget()

  useEffect(() => {
    if (page.theme) {
      setUnauthTheme(page.theme)
    }
  }, [setUnauthTheme, page.theme])

  const hideFooterConsumerBanner = hideFooterBanner || isOwnPage || isPresetFp

  const isFlowtagPageType = page?.pageType
    ? FLOWTAG_SHOW_BANNER_PAGETYPES.includes(page?.pageType.toUpperCase() as PageType)
    : false

  const isFlowtagPage = flowtagBannerEnabled && isFlowtagPageType

  const showFooterConsumerBanner = isFlowtagPage && !hideFooterConsumerBanner

  const hideFooter =
    isPresetFp || showFooterConsumerBanner || checkRemoveBrandingEnabled(page?.slots)
  const showHeader = page?.profileImage || page?.theme?.profileImageMaskType !== MaskType.NO_IMAGE
  const fontRequestUrl = getGoogleFontStyleUrlFor({ fontFamily, withFallback: true })
  const color = page?.theme?.fontColor ?? 'flowpage.contrastText'
  const titleAndDescriptionColor = page?.theme?.titleColor || color

  return (
    <ThemeProvider theme={userTheme}>
      {fontFamily !== FontFamilies.Inter && (
        <Head>
          <link href={fontRequestUrl} rel="stylesheet" />
        </Head>
      )}
      <FlowpageContainer
        bg="flowpage.style"
        minHeight={isPresetFp ? 'initial' : '100%'}
        {...flowpageContentContainerStyleProps}
      >
        {page?.theme?.backgroundImgUrl && (
          <BackgroundImage
            fill
            priority
            opacity={getBgOpacity(page.theme.style)}
            src={page.theme.backgroundImgUrl}
            alt="" // background images should not have alt text
            role="presentation"
            position={isPresetFp ? 'absolute' : 'fixed !important'}
            {...backgroundImageProps}
          />
        )}
        {showBanner && <PublishBanner pageId={page.id} />}
        <FlowPageWrapper showBanner={Boolean(showBanner)} preview={preview}>
          {showHeader ? <ProfileHeader page={page} /> : <Spacer mb="32px" />}
          <Box alignItems="center">
            <Text
              as="h1"
              textAlign="center"
              color={titleAndDescriptionColor}
              variant="display/large"
            >
              {page.displayName}
            </Text>
            <Verified verified={page?.verified} />
          </Box>
          <Spacer mb="8px" />
          <FlowpageCaptionText
            color={titleAndDescriptionColor}
            variant="body/medium"
            textAlign="center"
          >
            {page.caption}
          </FlowpageCaptionText>
          <Spacer mb="16px" />
          {linkObj.contact && (
            <DesktopContactBar contacts={contact[0]} preview={preview || isOwnPage} />
          )}
          <Links links={sortedCardLinks} />
          {fullPageLinks.map(link => {
            const { provider, type } = link
            const Component = getWidgetComponent(provider as LinkProvider, type as WidgetType)

            return Component && link.active && <Component key={link.id} link={link} />
          })}
        </FlowPageWrapper>
        <FlowpageModals />
        {!hideFooter && <PoweredByFooter preview={preview} contrastText={contrastText} />}
        {showFooterConsumerBanner && (
          <FlowpageConsumerBanner pageId={id} isFlowtagBanner={isFlowtagPage} />
        )}
      </FlowpageContainer>
    </ThemeProvider>
  )
}

export default FlowPageContent
