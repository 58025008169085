import {
  CHECKED_POS_VARIANTS,
  CHECKED_VARIANTS,
  RADIOBUTTON_SIZE_VARIANTS,
  RadioButtonSizeOptions
} from '../../../constants'
import { theme } from '../../../theme'
import { variant } from 'styled-system'
import styled from 'styled-components'

export interface RadioButtonStyleProps {
  variant: RadioButtonSizeOptions
  error?: boolean
  activeColor?: string
  disabled?: boolean
}
// TODO: add styling for error
export const RadioButtonBase = styled.label<RadioButtonStyleProps>`
  display: inline-flex;
  align-items: center;
  position: relative;
  ${({ disabled }) => !disabled && 'cursor: pointer;'}
  ${variant({
    prop: 'variant',
    variants: { ...RADIOBUTTON_SIZE_VARIANTS }
  })}
  width: auto;

  input {
    height: 0; // Visually hide the input, but still make it accessible
    width: 0;
    overflow: hidden;
    opacity: 0;
  }

  .radio-button_custom {
    position: absolute;
    top: 0;
    left: 0;
    border-style: solid;
    border-width: 2px;
    border-radius: 50%;
    box-sizing: border-box;
    transition: border 0.25s ease-out;
    border-color: ${theme.colors.secondary.border};
    background-color: #fff;
    ${variant({
      prop: 'variant',
      variants: { ...RADIOBUTTON_SIZE_VARIANTS }
    })}
  }

  .radio-button_custom::before {
    content: '';
    position: absolute;
    border-radius: 50%;
    height: 0;
    width: 0;
    transition: background 0.25s ease-out;
    ${variant({
      prop: 'variant',
      variants: { ...CHECKED_POS_VARIANTS }
    })}
  }

  input:checked ~ .radio-button_custom {
    border-style: solid;
    border-width: 2px;
    border-color: ${({ theme, activeColor }) =>
      activeColor ? activeColor : theme.colors.primary.flowBlue};
  }

  input:checked ~ .radio-button_custom::before {
    background: ${({ theme, activeColor }) =>
      activeColor ? activeColor : theme.colors.primary.flowBlue};
    ${variant({
      prop: 'variant',
      variants: { ...CHECKED_VARIANTS }
    })}
  }
`
