import { Asset, AssetType } from '@dtx-company/connect-codegen/src/gen/asset/v1/asset_pb'
import { CountData, Product } from '@dtx-company/connect-codegen/src/gen/analytics/v2/analytics_pb'
import { ITHACA_ENDPOINT } from '@dtx-company/true-common/src/constants/endpoints'
import { logger } from '@dtx-company/logger'
import { unpackAssetData } from './utils'
import analyticsClient from '@dtx-company/inter-app/src/connect-client/analyticsClient'
export interface AdditionalAssetInformation {
  ownersObject?: FetchAssetOwnersType
  flowcodeAnalytics?: CountData['flowcodeCounts']
  flowpageAnalytics?: CountData['flowpageCounts']
}
export interface FetchAssetOwnersType {
  [ithacaId: string]: string
}

export const fetchAdditionalAssetInformation = async (args: {
  ownerIds: string[]
  assetData?: Asset[]
  token?: string
}): Promise<AdditionalAssetInformation | undefined> => {
  try {
    const { ownerIds, assetData, token } = args
    const { pageIds, codeIds } = (assetData ?? []).reduce(
      (acc, thisAsset) => {
        if (thisAsset.assetType === AssetType.PAGE) {
          acc.pageIds.push(thisAsset.id)
        } else if (thisAsset.assetType === AssetType.CODE) {
          const unpackedCodeData = unpackAssetData(thisAsset.data)
          const batchId = unpackedCodeData.codeData?.batchId
          if (batchId) acc.codeIds.push(batchId)
        }
        return acc
      },
      { pageIds: [], codeIds: [] } as {
        pageIds: string[]
        codeIds: string[]
      }
    )

    const ownersObject = await fetchOwnerList(ownerIds)
    const flowcodeAnalytics = await fetchFlowcodeAnalytics({ codeIds, token })
    const flowpageAnalytics = await fetchFlowpageAnalytics({ pageIds, token })

    return { ownersObject, flowcodeAnalytics, flowpageAnalytics }
  } catch (e) {
    logger.logError(e, { team: 'platform-products', tag: 'unified-asset-management' })
  }
}

/* given an array of ownerIds, 
returns an object of formatted owner name values with ownerId as key
*/
export const fetchOwnerList = async (
  ithacaIds: string[]
): Promise<FetchAssetOwnersType | undefined> => {
  if (ithacaIds.length > 0) {
    try {
      const data = await fetch(`${ITHACA_ENDPOINT}/v2/user-list?ithacaIds=${ithacaIds}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      if (data.users) {
        const usersObject = data.users.reduce(
          (
            acc: FetchAssetOwnersType,
            curr: { ithacaId: string; firstName?: string; lastName?: string; email?: string }
          ) => {
            let name = ''
            name = `${curr?.firstName ?? ''} ${curr?.lastName ?? ''}`
            if (name === ' ') {
              name = curr?.email ?? ''
            }
            acc[curr.ithacaId] = name
            return acc
          },
          {}
        )
        return usersObject
      }
    } catch (e) {
      logger.logError(e, { team: 'platform-products', tag: 'unified-asset-management' })
      console.error(e)
    }
  }
}

export const fetchFlowpageAnalytics = async (args: {
  pageIds?: string[]
  token?: string
}): Promise<CountData['flowpageCounts'] | undefined> => {
  const { pageIds, token } = args
  if (pageIds?.length) {
    try {
      const resp = await analyticsClient.count(
        {
          pageId: pageIds,
          allAssets: true,
          product: Product.FLOWPAGE
        },
        { headers: { Authorization: 'Bearer ' + token } }
      )
      if (resp.data?.flowpageCounts) {
        return resp.data?.flowpageCounts
      }
    } catch (e) {
      logger.logError(e, { team: 'platform-products', tag: 'unified-asset-management' })
    }
  }
}

const fetchFlowcodeAnalytics = async (args: {
  codeIds?: string[]
  token?: string
}): Promise<CountData['flowcodeCounts'] | undefined> => {
  const { codeIds, token } = args
  if (codeIds?.length) {
    try {
      const resp = await analyticsClient.count(
        {
          assetId: codeIds,
          allAssets: true,
          product: Product.FLOWCODE
        },
        { headers: { Authorization: 'Bearer ' + token } }
      )
      if (resp.data?.flowcodeCounts) {
        return resp.data?.flowcodeCounts
      }
    } catch (e) {
      logger.logError(e, { team: 'platform-products', tag: 'unified-asset-management' })
    }
  }
}
