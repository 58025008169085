import { DEFAULT_PAGE_ID } from '@dtx-company/true-common/src/constants/page'
import { IthacaFilter } from '@dtx-company/flow-codegen/src/page/generated.types'
import { PageType, PaginatedLeanPages } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { RootPageState } from '../../../redux/types'
import { useAuthState } from '../../../hooks/useAuthState'
import { useCurrentPageId } from '../../../hooks/useCurrentPageId'
import { useGetFirstPage } from './pagination'
import { usePageCount, usePageCountWithLoading } from './pageCount'
import {
  usePageDetailQuery,
  usePageManagementQuery
} from '../../../redux/slices/flowpageApiSlice/pageQueries'
import { usePageSelector } from '../../../hooks/usePageSelector'
import { usePageTemplateEditorState } from '../../../hooks/usePageTemplateEditorState'

export function useGetAllPages(
  skip = false,
  ithacaFilter?: IthacaFilter
): { pages: Record<string, PageType>; isLoading: boolean; refetch: () => void } {
  const { refetch, totalCount } = usePageCountWithLoading(ithacaFilter)
  const { data, isLoading } = usePageManagementQuery({ first: totalCount, ithacaFilter }, { skip })

  if (data?.me?.pageConnection?.edges) {
    return {
      pages: Object.values(data?.me?.pageConnection?.edges).reduce((acc, curr) => {
        return { ...acc, ...(curr?.id && { [curr.id]: curr }) }
      }, {}),
      isLoading,
      refetch
    }
  }

  return { isLoading, pages: {}, refetch }
}

export function useGetAllPagesListWithLoading(): {
  pages: PaginatedLeanPages | undefined
  isLoading: boolean
} {
  const pageCount = usePageCount()
  const { data, isLoading } = usePageManagementQuery({ first: pageCount })
  return { pages: data?.me?.pageConnection?.edges, isLoading }
}

/**
 * @deprecated in favor of usePageEditorCurrentPage. In an effort to stop using redux
 * as the source of truth for the current pageId and instead use the pageId from the
 * query param this hook is being phased out
 */
export function useCurrentPage(pageId?: string): PageType | null | undefined {
  const statePageId = useCurrentPageId()

  const { isAuthChecked } = useAuthState()
  const { isTemplateEditor, templateId } = usePageTemplateEditorState()

  const firstPage = useGetFirstPage()
  const queryId = isTemplateEditor ? templateId : statePageId || pageId || firstPage?.id
  const { data } = usePageDetailQuery(
    {
      id: queryId || '',
      isPageTemplate: isTemplateEditor
    },
    {
      skip: !queryId || !isAuthChecked || queryId === DEFAULT_PAGE_ID || queryId === '[id]'
    }
  )

  const tempPage = usePageSelector((state: RootPageState) => {
    return state.pageEditorReducer.tempPage
  })

  if (statePageId === 'default-page' && !isTemplateEditor) return tempPage

  return data?.Page
}

/**
 * @deprecated in favor of usePageEditorCurrentPage. In an effort to stop using redux
 * as the source of truth for the current pageId and instead use the pageId from the
 * query param this hook is being phased out
 */
export function useCurrentPageOrNull(): PageType | null {
  const pageId = useCurrentPageId()

  const { isAuthChecked } = useAuthState()
  const firstPage = useGetFirstPage()
  const { isTemplateEditor, templateId } = usePageTemplateEditorState()

  const queryId = isTemplateEditor ? templateId : pageId || firstPage?.id
  const { data } = usePageDetailQuery(
    {
      id: queryId || '',
      isPageTemplate: isTemplateEditor
    },
    {
      skip: !queryId || !isAuthChecked || queryId === DEFAULT_PAGE_ID
    }
  )

  const tempPage = usePageSelector((state: RootPageState) => {
    return state.pageEditorReducer.tempPage
  })

  if (pageId === 'default-page' && !isTemplateEditor) return tempPage
  else {
    return data?.Page || null
  }
}

/**
 * @deprecated in favor of usePageEditorCurrentPage. In an effort to stop using redux
 * as the source of truth for the current pageId and instead use the pageId from the
 * query param this hook is being phased out
 */
export function useCurrentPageWithLoading(pageId?: string): {
  currentPage: PageType | null | undefined
  isLoading: boolean
} {
  const { isTemplateEditor, templateId } = usePageTemplateEditorState()
  const { isAuthChecked } = useAuthState()
  const firstPage = useGetFirstPage()
  const id = isTemplateEditor ? templateId : pageId || firstPage?.id || ''
  const { data, isLoading } = usePageDetailQuery(
    {
      id: id || '',
      isPageTemplate: isTemplateEditor
    },
    { skip: !id || !isAuthChecked || id === DEFAULT_PAGE_ID }
  )
  const tempPage = usePageSelector((state: RootPageState) => {
    return state.pageEditorReducer.tempPage
  })

  if (pageId === DEFAULT_PAGE_ID && !isTemplateEditor) return { currentPage: tempPage, isLoading }

  return { currentPage: data?.Page, isLoading }
}

export function useHasUserReachedPageLimit(pageLimit: number): boolean {
  const pageCount = usePageCount()
  if (pageLimit === 0) return true
  return pageCount && pageLimit > 0 ? pageCount >= pageLimit : false
}

export function useRemainingNumberOfPages(pageLimit: number): number {
  const pageCount = usePageCount()
  if (pageLimit === -1) return Infinity
  if (pageLimit === 0) return 0
  return pageLimit - pageCount
}
